import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import ReactMarkdown from "react-markdown"
import gfm from "remark-gfm"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import HeaderTop from "../components/HeaderTop"
import HeaderColor from "../components/HeaderColor"
import RelatedProduct from "./related-product"
import Footer from "../components/Footer"
import CallToAction from "../components/CallToAction"
import ButtonWrapper from "../components/Button"
import options from "../../config/options"
import { Row, Col } from "antd"
import styled from "@emotion/styled"

const PageWrapper = styled.div`
  margin: 60px 0 50px 0;
  .contentWrapper {
    max-width: 1000px;
    margin: 0 auto;
  }
  h1.title {
    font-family: "Prompt", sans-serif;
    font-weight: 500;
    font-size: 26px;
    color: #323232;
    margin-bottom: 20px;
  }
  .featuredWrapper {
    margin: 30px 0;
  }
  .htmlContentWrapper {
    padding: 10px 0 10px 0;
    font-family: "Prompt", sans-serif;
    color: #848383;
    h2,
    h3 {
      font-weight: 500;
      color: #323232;
    }
    h2 {
      font-size: 26px;
    }
    h3 {
      font-size: 22px;
    }
    img {
      max-width: 100%;
      height: auto;
    }
    b,
    strong {
      font-family: "Prompt", sans-serif;
      font-weight: 600;
    }
    ul li,
    p {
      font-family: "Prompt", sans-serif;
      font-weight: 300;
      font-size: 16px;
      color: #848383;
    }
    table {
      table-layout: fixed;
      max-width: 100%;
      width: 100%;
    }
    .centerElement {
      margin: 0 auto;
      text-align: center;
    }
  }
  @media only screen and (min-width: 768px) {
    h1.title {
      font-size: 32px;
    }
    .htmlContentWrapper {
      h2 {
        font-size: 32px;
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    h1.title {
      font-size: 40px;
    }
    .htmlContentWrapper {
      h2 {
        font-size: 40px;
      }
    }
  }
`
const ContactWrapper = styled.div`
  background: #f5f5f5;
  margin: 0 auto;
  text-align: center;
  padding: 60px 0;
  h3,
  h4 {
    font-family: "Prompt", sans-serif;
    font-weight: 500;
    font-size: 26px;
    color: #323232;
    margin-bottom: 15px;
  }
  h4 {
    font-size: 22px;
    margin-bottom: 0;
  }
  .divWrapper {
    margin-top: 35px;
  }
  @media only screen and (min-width: 768px) {
    h3 {
      font-size: 32px;
    }
  }
  @media only screen and (min-width: 1200px) {
    h3 {
      font-size: 40px;
    }
  }
`

const ProductDetails = ({ data }) => {
  const { node } = data.allContentfulProduct.edges[0]

  if (node) {
    return (
      <Layout>
        <SEO
          title={node.title}
          description={node.seoDescription?.seoDescription}
          banner={node.featured ? node.featured.fluid.src : ""}
          article
        />
        <HeaderTop text={options.headerTop} />
        <HeaderColor />
        <PageWrapper>
          <div className="gb-container">
            <div className="contentWrapper">
              <Row>
                <Col span={24}>
                  <div className="featuredWrapper">
                    <Img
                      loading="eager"
                      fadeIn={false}
                      fluid={node.featured.fluid}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <h1
                    className="title"
                    dangerouslySetInnerHTML={{
                      __html: node.title,
                    }}
                  ></h1>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className="htmlContentWrapper">
                    <ReactMarkdown
                      plugins={[gfm]}
                      children={node.contentFull?.contentFull}
                      allowDangerousHtml={true}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </PageWrapper>
        <ContactWrapper>
          <div className="gb-container">
            <Row>
              <Col span={24}>
                <h3>ติดต่อ-สั่งซื้อ Tel. 080-986-9999</h3>
                <h4>“บริการส่งฟรี! ถึงบ้าน ทั่วไทย”</h4>
                <div className="divWrapper">
                  <ButtonWrapper
                    text="สั่งซื้อผ่านไลน์ คลิ๊ก"
                    cta={options.lineCTA}
                    path="target"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </ContactWrapper>
        <RelatedProduct slug={node.slug} />
        <Footer />
        <CallToAction />
      </Layout>
    )
  } else {
    return null
  }
}

export default ProductDetails

export const query = graphql`
  query productSingleQuery($slug: String!) {
    allContentfulProduct(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          seoDescription {
            seoDescription
          }
          slug
          title
          contentFull {
            contentFull
          }
          createdAt(formatString: "MMMM DD, YYYY")
          featured {
            fluid(maxWidth: 1200) {
              ...GatsbyContentfulFluid_withWebp
              src
            }
          }
        }
      }
    }
  }
`
